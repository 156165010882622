<template>
    <div class="container-fluid">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                <div class="col-sm-6">
                    <h1>Gestor de tipos</h1>
                </div>            
                </div>
            </div><!-- /.container-fluid -->
        </section>
        <section class="content">
            <div class="card card-warning">
                <div class="card-header d-flex" style="justify-content:end">                   
                    <div class="row">
                        <input placeholder="Nombre tipo" v-model="nuevo" type="text" style="margin-right: 10px;">
                        <button class="btn btn-secondary" @click="agregarTipo()">Agregar</button>
                    </div>
                </div>
                <div class="card-body">  
                    <table class="table table-striped">
                        <tbody>
                            <tr v-for="tipo in tipos" :key="tipo.id">                                        
                                <td width="80%" >{{ tipo.nombre }}</td>                               
                                <td width="1%"><button class="btn btn-light btn-sm"   @click="abrirDatos(tipos.indexOf(tipo))"><i class="fas fa-edit mr-2" title="Editar"></i></button></td>
                                <td width="1%"><button class="btn btn-light btn-sm"   @click="eliminarTipo(tipo.id)"><i class="fas fa-trash-alt mr-2" title="Eliminar"></i></button></td>
                                <td width="18%"></td>
                                <datos-tipo ref="ver" :tipo="tipo"/>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import { PwgsApi } from '../../../../services/PwgsApi';
import datosTipo from './datostipo.vue'
export default {
    //crear plantilla texto pide nombre y tipo
    components: {
        'datos-tipo': datosTipo,
    },
    data() {
        return {
            tipos:[],
            nuevo:'',
        }
    },
    methods: {
        async eliminarTipo(id){
            const api = new PwgsApi();
            const res = await api.delete('tipos-servicio/'+id);
            console.log('res', res);
            this.obtenerTipos();
        },
        abrirDatos(indice){
            this.$refs.ver[indice].hacerVisible();
        },
       async obtenerTipos(){
        const api = new PwgsApi();
        const res = await api.get('tipos-servicio');
        this.tipos = res.datos;
       },
       async agregarTipo(){
        if(this.nuevo == ''){
            alert('Debes introducir un nombre');
        }else{
            const api = new PwgsApi();
            const body = {nombre:this.nuevo}
            const res = await api.post('tipos-servicio/' , body);
            console.log('res', res);
            this.obtenerTipos();
            this.nuevo = '';
        }
       }
    },
    watch:
    {
          
    },
    mounted() { 
        this.obtenerTipos();
    },
}

</script>
